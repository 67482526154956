<template>
  <div>
    <h2 class="device__title">THC 처리 통합 모니터링 시스템</h2>
    <CCard>
        <CRow>
            <CCol col="12" xl="12">
                <img src="./thcs_image.png" width="100%" class="mx-auto d-block" />
            </CCol>
        </CRow>
    </CCard> 
  </div>
</template>

<script>
export default {
    props: {
        device: {
            type: Object
        },
    },
}
</script>

<style scoped lang="scss">
.device__title {
    text-align: center;
    background-color: #0e1575;
    color: white;
    border-radius: 16px 16px 0 0;
    height: 40px;
    line-height: 40px;
}
</style>