<template>
  <div>
    <h2 class="table__title">가스 유입 상태</h2>
    <table class="table table-bordered table text-center" style="font-size: 14px;">
        <tbody>
            <tr>
                <th scope="row">Flow1</th>
                <td>{{ flow_datas[0] }} <span class="unit">L/m</span></td>
            </tr>
            <tr>
                <th scope="row">Flow2</th>
                <td>{{ flow_datas[1] }} <span class="unit">L/m</span></td>
            </tr>
            <tr>
                <th scope="row">Flow3</th>
                <td>{{ flow_datas[2] }} <span class="unit">L/m</span></td>
            </tr>
            <tr>
                <th scope="row">Flow4</th>
                <td>{{ flow_datas[3] }} <span class="unit">L/m</span></td>
            </tr>
            <tr>
                <th scope="row">Bubbler Heater</th>
                <td>{{ flow_datas[7] }} <span class="unit">L/m</span></td>
            </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
export default {
    props: {
      device: {
          type: Object
      },
    },    
    watch: {
        device: function(new_val, old_val) {
            this.sensorValue()
        }
    },       
    data() {
        return {
            flow_datas: [0,0,0,0,0,0,0,0,0,0,0,0],
            temp_datas: [0,0,0,0,0,0,0,0,0,0,0,0],
        }
    },
    methods: {
        sensorValue() {
            let value = this.device.sensor.data
            let arr1 = value[0].value
            let arr2 = value[1].value
            let arr3 = value[2].value
            //let data = arr1.concat(arr2, arr3)
            //let result = this.splitIntoChunk(data, 3)
            //console.log("result : ", result)
            this.temp_datas = arr2;
            this.flow_datas = arr3;
        },
    }
}
</script>

<style lang="scss" scoped>
.table__title {
    text-align: center;
    background-color: #0e1575;
    color: white;
    border-radius: 16px 16px 0 0;
    height: 40px;
    line-height: 40px;
}
.unit {
    font-size: 11px;
}
</style>