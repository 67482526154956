<template>
  <div>
    <h2 class="status__title">THC 처리장치 운영 현황</h2>
    <!-- 상단 -->
    <CRow>
        <CCol col="12" xl="6">
            <CCard>
                <CRow>
                    <CCol col="12" xl="6">
                        <h3 class="status__subtitle">유입부</h3>
                        <div class="contentWrapper">
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(flow_datas[5])" :size="100" thickness="5" dot="9" fontSize="1.4rem" :color="gradient">
                                    <span slot="legend-value"></span>
                                    <!-- <p slot="legend-caption">legend-caption</p> -->
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">유량<span class="unit">㎥/hr</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(dens_datas[3])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                    <!-- <p slot="legend-caption">legend-caption</p> -->
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">농도<span class="unit">ppm</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(dens_datas[0])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">온도<span class="unit">℃</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                        </div>
                    </CCol>
                    <CCol col="12" xl="6">
                        <h3 class="status__subtitle">유출부</h3>
                        <div class="contentWrapper">
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(flow_datas[6])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                    <!-- <p slot="legend-caption">legend-caption</p> -->
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">유량<span class="unit">㎥/hr</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(dens_datas[4])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                    <!-- <p slot="legend-caption">legend-caption</p> -->
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">농도<span class="unit">ppm</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(dens_datas[1])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">온도<span class="unit">℃</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol col="12" xl="6">
                        <h3 class="status__subtitle">배기부</h3>
                        <div class="contentWrapper">
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(flow_datas[7])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                    <!-- <p slot="legend-caption">legend-caption</p> -->
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">유량<span class="unit">㎥/hr</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(dens_datas[5])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                    <!-- <p slot="legend-caption">legend-caption</p> -->
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">농도<span class="unit">ppm</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(dens_datas[2])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">온도<span class="unit">℃</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                        </div>
                    </CCol>
                    <CCol>
                         <h3 class="status__subtitle">라인히터</h3>
                        <div class="contentWrapper">
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(dens_datas[4])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                    <!-- <p slot="legend-caption">legend-caption</p> -->
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">유량<span class="unit">㎥/hr</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                            <div class="output">
                                <vue-ellipse-progress :progress="Number(dens_datas[6])" :size="100" thickness="5" dot="9" fontSize="1.4rem" color="#1fb73b">
                                    <span slot="legend-value"></span>
                                    <!-- <p slot="legend-caption">legend-caption</p> -->
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <h3 class="title">온도<span class="unit">℃</span></h3>
                                    <!-- <p class="data">가동 <span style="color: blue;">2</span> / 중지 <span style="color: red;">1</span></p> -->
                                </div>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
        <CCol col="12" xl="6">
            <CCard>
                <CRow>
                    <CCol col="12" xl="6">
                        <h3 class="status__subtitle">돌발정비 및 중지</h3>
                        <br />
                        <br />
                        <div class="contentWrapper">
                            <div class="output">
                                <vue-ellipse-progress :progress="100" :size="178" thickness="8" color="#FFB732" fontSize="1.8rem">
                                    <div>총</div>
                                    <div>2 건</div>
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <br />
                                    <p class="data">발생 <span style="color: blue;">3</span> / 처리 <span style="color: red;">1</span></p>
                                </div>
                            </div>
                            <div class="output">
                                <div class="alarm" style="align-items: center;">
                                    <div class="detail">
                                        <p>＊</p>
                                        <p>2023.08.20_09:50 흡착탑#1 기압 낮음</p>
                                    </div>
                                    <div class="detail">
                                        <p>＊</p>
                                        <p>2023.08.20_09:50 흡착탑#1 기압 낮음</p>
                                    </div>
                                    <div class="detail">
                                        <p>＊</p>
                                        <p>2023.08.20_09:50 흡착탑#1 기압 낮음</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </CCol>
                    <CCol col="12" xl="6">
                        <h3 class="status__subtitle">알림</h3>
                        <br />
                        <br />
                        <div class="contentWrapper">
                            <div class="output">
                                <vue-ellipse-progress :progress="100" :size="178" thickness="8" color="#FFB732" fontSize="1.8rem">
                                    <div>
                                        총
                                    </div>
                                    <div>
                                        1 건
                                    </div>
                                </vue-ellipse-progress>
                                <div class="dataWrapper">
                                    <br />
                                    <p class="data">발생 <span style="color: blue;">10</span> / 처리 <span style="color: red;">10</span></p>
                                </div>
                            </div>
                            <div class="output">
                                <div class="alarm">
                                    <div class="detail">
                                        <p>＊</p>
                                        <p class="xxx">2023.08.20_09:50 흡착탑#1 기압 낮음</p>
                                    </div>
                                    <div class="detail">
                                        <p>＊</p>
                                        <p class="xxx">2023.08.20_09:50 흡착탑#1 기압 낮음</p>
                                    </div>
                                    <div class="detail">
                                        <p>＊</p>
                                        <p class="xxx">2023.08.20_09:50 흡착탑#1 기압 낮음</p>
                                    </div>
                                    <div class="detail">
                                        <p>＊</p>
                                        <p class="xxx">2023.08.20_09:50 흡착탑#1 기압 낮음</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
    </CRow>
  </div>
</template>

<script>
import { VueEllipseProgress } from 'vue-ellipse-progress'

export default {
    components: {
        VueEllipseProgress
    },
    props: {
      device: {
          type: Object
      },
    },
    watch: {
        device: function(new_val, old_val) {
            this.sensorValue()
        }
    },    
    data() {
        return {
            dens_datas: [0,0,0,0,0,0,0,0,0,0,0,0],
            temp_datas: [0,0,0,0,0,0,0,0,0,0,0,0],
            flow_datas: [0,0,0,0,0,0,0,0,0,0,0,0],
            greenColor: {},
            gradient: {
                radial: false,
                colors: [
                {
                    color: '#6546f7',
                    offset: "0",
                    opacity: '1',
                },
                {
                    color: 'lime',
                    offset: "100",
                    opacity: '0.6',
                },
                ]
            }
        }
    },
    methods: {
        sensorValue() {
            let value = this.device.sensor.data
            let arr1 = value[0].value
            let arr2 = value[1].value
            let arr3 = value[2].value
            //let data = arr1.concat(arr2, arr3)
            //let result = this.splitIntoChunk(data, 3)
            this.temp_datas = arr1;
            this.dens_datas = arr2;
            this.flow_datas = arr3;
            //console.log("result : ", this.dens_datas)

        },
    }
}
</script>

<style scoped lang="scss">
.status__title {
    text-align: center;
    background-color: #0e1575;
    color: white;
    border-radius: 16px 16px 0 0;
    height: 40px;
    line-height: 40px;
}
.status__subtitle {
    text-align: center;
    background-color: #d9d9d9;
    height: 34px;
    line-height: 34px;
    font-size: 22px;
    font-weight: bold;
    color: #0e1575;
    margin-bottom: 3%;
    .contentWrapper {
        .output {
            .text {
                font-size: 16px;
                padding: 2%;
                .data {
                    display: flex;
                }
            }
        }
    }
}
.contentWrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    .output {
        text-align: center;
        width: 100%;
        .dataWrapper {
            .title {
                font-size: 18px;
                font-weight: bold;
            }
            .data {
                font-size: 20px;
                font-weight: bold;
            }
            .unit {
                font-size: 14px;
            }
        }
    }
}

.alarm {
    overflow-y: scroll;
    .detail {
        display: flex;
        text-align: left;
        font-size: 14px;
        justify-content: center;
        align-items: center;
    }
}

.result {
    font-size: 22px;
    text-align: center;
}

</style>