<script>
import moment from 'moment';
import { DATE } from '@/constants/constant'
import utils from '@/services/utils';
import Chart from '@/views/device/Chart'

export default {
  name: 'Chart_THCS',
  extends: Chart,
  methods: {
    generate(contents) {
      var content = _.first(contents);
      var self = this;
      this.title = content.title;
      this.icon = content.icon;
      var { type, sensors, infos, guid, date_type, image_name } = content;
      this.device_guid = guid;
      this.options.type = type;
      if(Number(type) == 35)
        this.title = "NOVA A&B"
      else if(Number(type) == 38)
        this.title = "MFC(가스유량)"

      var yaxis = {
        type: 'value',
        min: 0,
        max: 100,
        splitLine: {
          show: false
        }
      };
    
      yaxis.boundaryGap = [0, '100%']
      yaxis.min = yaxis.max = undefined;

      var getSymbolVisibility = function(date_type, size) {
        if (date_type == 1 || date_type == 3) { //rawdata | hourly
          if (size < 72)
            return true;
          else
            return false;
        }
        return date_type == 2 && size < 36 ? true : false;
      }

      var makeColor = function(value, sensor_index, device_index) {
        //var v = Number(value); // init : 1
        var r = (sensor_index - 1) * 102;      
        var g = ((device_index + sensor_index - 1) * 102) % 204;
        var b = (device_index - 1) * 102;

        if(sensor_index >= 4){
          r = Math.abs(parseInt((Math.random()*204) + 50));
          g = Math.abs(parseInt((Math.random()*204) + 20));
          b = Math.abs(parseInt((Math.random()*204) + 5));
        }
        // var r = Math.abs(parseInt((Math.random()*102) + 100));
        // var g = ((device_index + sensor_index - 1) * 102) % 204;
        // var b = (device_index - 1) * 102;
        var rgb = 'rgb(' + (r) + ', ' + (g) + ', ' + (b) + ')';
        //console.log(rgb)
        return rgb;
      }

      var buildMarkLine = function(sensor_type, sensor_id) {
        var thresholds = [];
        var device_threshold = self.$parent.device.threshold
        var filtered = _.filter(_.isEmpty(device_threshold) ? self.thresholds : device_threshold, function(o) {
          if (o.sensor_type == sensor_type) {
            thresholds.push(o);
          }
        });
        var show_line = sensor_type == 1 || sensor_type == 2 || (sensor_type % 64 == 12 && sensor_id == 1) ? true : false;
        if (show_line === false) return;

        var markLine = {
          show: show_line,
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: {
            normal: {
              width: 0.7,
              // type: 'dashed',
              type: 'solid',
            }
          },
          data: []
        }

        var getLabel = function(name) {
          if (!name || name.length <= 2) {
            return "";
          }
          return name.substring(name.length - 2, name.length);
        }

        for (var i = 0; i < thresholds.length; i++) {
          if(sensor_type == thresholds[i].sensor_type && sensor_id == thresholds[i].sensor_id){
            markLine.data.push({
              yAxis: thresholds[i].max,
              lineStyle: {
                normal: {
                  color: thresholds[i].color
                }
              },
              label: {
                normal: {
                  show: true,
                  position: 'end',
                  formatter: getLabel(thresholds[i].name)
                }
              }
            })
          }
        }
        return markLine;
      }

      var buildDataView = function(date_type) {
        var optionToContent = function(opt) {
          var axisData = opt.xAxis[0];
          var fmt = date_type === DATE.TYPE.DAILY ? 'YYYY-MM-DD' :'YYYY-MM-DD HH:mm:ss';

          var series = _.first(opt.series);
          var td_series_name = '<tr><td>일시</td>';
          var max_index = 0;
          for (var i = 0; i < opt.series.length; i++) {
            td_series_name += '<td>' + opt.series[i].name + '</td>'
            if (series.length < opt.series[i].length) {
              series = opt.series[i];
              max_index = i;
            }
          }
          td_series_name += '</tr>'

          var td_series_data = '';
          for (var i = 0; i < series.data.length; i++) {
            td_series_data += '<tr><td>' + moment(series.data[i][0]).format(fmt) + '</td>'
            for (var k = 0; k < opt.series.length; k++) {
              var selected = _.filter(opt.series[k].data, function(o) {
                return o[0] == series.data[i][0]
              });
              var selected_data = _.isEmpty(selected) ? '' : selected[0][1];
              td_series_data += '<td>' + selected_data + '</td>'
            }
            td_series_data += '</tr>'
          }
          var table = '<table style="width:100%;text-align:left;border: 1px solid #444444"><tbody>' +
            td_series_name +
            td_series_data +
            '</tbody></table>';
          return table;
        }
        return optionToContent;
      }

      var sensor_types = [];
      var legendName = []
      var sensor_name = undefined;
      var device_index = 1;
      var sensor_index = 1;

      var series_datas = []

      { //
        _.filter(contents, function(content, noop) {
          var { type, sensors, infos, guid, date_type } = content;

          _.filter(sensors, function(value, id) {
            if (typeof infos[guid] !== 'undefined') {

              var sid = Number(id) + 1

              if(type == 8){
                if(sid >=4 && sid <= 6){
                  return;
                }
              }
              
              sensor_name = utils.getSensorTypeName(type, sid, true);
              var name = infos[guid].name + '_' + sensor_name;
              legendName.push(name);
              series_datas.push({
                name: name,
                sensor_type: type,
                device_name: infos[guid].name,
                sensor_name: sensor_name,
                type: 'line',
                data: value,
                guid: guid,
                showAllSymbol: true,
                showSymbol: getSymbolVisibility(date_type, value.length),
                // showSymbol: true,
                symbolSize: 10,
                // symbolSize: 5,
                smooth: false,
                itemStyle: {
                  normal: {
                    color: makeColor(type, sensor_index++, device_index),
                    lineStyle: {
                      width: 2,
                      type: 'solid'
                    }
                  }
                },
                markLine: buildMarkLine(type, sid)
              })
            } // if
          }); // _.filter(sensors)
          device_index++;
          sensor_index = 1;

        }); // _.filter(contents
      } // 

      var type35_label_data = ['기압1','기압2','기압3','자켓히터1','자켓히터2','자켓히터3','온도1','온도2','온도3','라인히터1','라인히터2','라인히터3','라인온도1','라인온도2','라인온도3','농도1','농도2','농도3','버블히터','프리히터'];
      var type38_label_data = ['FLOW1','FLOW2','FLOW3','FLOW4','FLOW5','유입','유출','연소'];

      for (var i = 0; i < series_datas.length; i++) {
        if(series_datas[i].sensor_type == 35){
          series_datas[i].name = type35_label_data[i];
          legendName[i] = type35_label_data[i];
        }
        else if(series_datas[i].sensor_type == 38){
          series_datas[i].name = type38_label_data[i];
          legendName[i] = type38_label_data[i];
        }
      }
      var splitNumber = 5;
      var legend_option = {
        // type: 'scroll',
        // orient: 'vertical',
        show: true,
        top: 30,
        left: 250,
        data: legendName
      }
      var grid_option = {
        top: '25%'
      }

      this.options.calculable = true;
      this.options.xAxis[0].splitNumber = splitNumber;
      this.options.yAxis = [yaxis];
      this.options.toolbox.feature.saveAsImage.name = image_name + "(" + sensor_name + ")";
      this.options.toolbox.feature.dataView.optionToContent = buildDataView(date_type);
      this.options.tooltip = {
          // trigger: 'axis',
          // formatter: function(params) {
          //   if (params.length < 1) return '';

          //   var texts = []
          //   var fmt = date_type === DATE.TYPE.DAILY ? 'YYYY/MM/DD' :'YYYY/MM/DD HH:00';
          //   texts.push(moment(params[0].value[0]).format(fmt));
          //   for (var i = 0; i < params.length; i++) {
          //     texts.push(params[i].marker + params[i].seriesName + ' : ' + params[i].value[1]);
          //   }
          //   return texts.join('<br>');
          // },
          axisPointer: {
            animation: false
          },
          enabled: false
        };
      this.options.title.text = this.title;
      this.options.grid = grid_option;
      this.options.legend = legend_option;
      this.options.series = series_datas
    }
  }, // method
}
</script>
