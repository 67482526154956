<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Remote Dialog"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">벨브 테스트</h6>
    </template>

    <div class='modal-body'>
      <CRow v-if="which!='man'">
        <CCol col="12" xl="12">
            <label><strong>원격제어 설정</strong></label><br/>
            <label><strong style="color:red;">* 경고: </strong><strong>본 제어는 시설 점검을 위한 기능임.</strong></label><br/>
            <label><strong style="color:red;">* 반드시 THC 및 가스 유입 없는 상태에서 진행할 것</strong></label>
        </CCol>
      </CRow>

      <div class="row-margin"></div>

      <CRow>
        <CCol col="12" xl="12">
          <v-table id="settingTable" :columns="columns" :data="data" :options="options"></v-table>
        </CCol>
      </CRow>
    </div>

    <template #footer>
      <CButton @click="execute()" color="info">실행</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>
    
  </KModal>
</template>

<script>

import WashingDialog from "@/views/btfs/WashingDialog"
import {
  REMOTE_CONTROL_TABLE_OPTIOINS, REMOTE_CONTROL_TABLE_COLUMS, REMOTE_CONTROL_TYPE
} from "@/views/thcs/remoteSettings";

export default {
  name: 'THCSValveTestDialog',
  extends: WashingDialog,
  data () {
    return {
      type: 'thcs',
      device: {},
      options: REMOTE_CONTROL_TABLE_OPTIOINS,
      columns: REMOTE_CONTROL_TABLE_COLUMS,
    }
  },
  methods: {
    execute() {
      var self = this;
      var enabled = _.filter(this.modified, {enable:true});//this.modified;//_.filter(this.modified, {enable:true});
      var data = {
        command: 'exec',
        device_guid: this.device.guid,
        params: [{
          cmd: 'actu',
          type: 'valve',
          data: {
            type: this.which,
            action: !enabled.length ? this.active ? 1 : 0 : 1
          },
          site_guid: this.device.site_guid
        }]
      }
      enabled.forEach(e => {
        data.params[0].data[e.field] = 1
      })

      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          this.showModal = false;
        })
        .catch(err => {
          console.log('WashingDialog execute error: ', err.toString())
          var message = err.toString();
          if(message == "Error: Already running command: run after a minutes"){
            message = "이미 처리중인 작업이 있습니다. 잠시만 기다려주세요.";
          }
          
          this.$refs.notifyDialog.show(message.toString())
          setTimeout(function() {
            //self.showModal = false;
          }, 3000)
        })
    },
    switchTrigger() {
      var self = this;
      this.data.forEach((el, i) => {
        var name = 'switch-' + i;
        var elem = document.getElementById(name);
        elem.addEventListener("click", function() {
          self.modified[i].enable = !self.modified[i].enable;
        }, false);
      })
    },
    show(which='auto', device) {
      var self = this
      var type = _.get(device, "service_type", "thcs"); // || submerged
      if (!_.get(REMOTE_CONTROL_TYPE, type)) {
        console.log('WashingDialog::show error - unknown type:', type);
        return;
      }
      this.which = which
      this.type = type
      this.device = device;
      this.title = which == 'auto' ? '자동' : which == 'semi' ? 'THC 처리기' : '수동';
      this.data = _.cloneDeep(REMOTE_CONTROL_TYPE[type]);
      this.modified = _.cloneDeep(REMOTE_CONTROL_TYPE[type]);
      this.showModal = true
      setTimeout(function() {
        //self.timeTrigger()
        self.switchTrigger()
      }, 100)
    }
  }
}
</script>