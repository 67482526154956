const REMOTE_CONTROL_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    striped: false,
    bordered: false,
    pagination: false,
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: false,
    singleSelect: false,
    showToggle: false
}

const REMOTE_CONTROL_TABLE_COLUMS = [
    {
        field: 'item',
        title: '항목',
        align: 'center',
        valign: 'top',
        sortable: false,
    }, {
        field: 'time',
        title: '시간(초)',
        align: 'center',
        valign: 'top',
        sortable: false,
        visible: false
    }, {
        field: 'enable',
        title: '설정',
        align: 'center',
        valign: 'top',
        sortable: false,
    }
]

const REMOTE_CONTROL_TYPE = {
    'thcs' : [
        {
            item: 'A1_TOP',
            field: 'a1_top',
            time: 20,
            enable: false
        }, {
            item: 'A1_BOM',
            field: 'a1_bom',
            time: 20,
            enable: false
        }, {
            item: 'A2_TOP',
            field: 'a2_top',
            time: 10,
            enable: false
        }, {
            item: 'A2_BOM',
            field: 'a2_bom',
            time: 10,
            enable: false
        },
        {
            item: 'A3_TOP',
            field: 'a3_top',
            time: 20,
            enable: false
        }, {
            item: 'A3_BOM',
            field: 'a3_bom',
            time: 20,
            enable: false
        }, {
            item: 'D1_TOP',
            field: 'd1_top',
            time: 10,
            enable: false
        }, {
            item: 'D1_BOM',
            field: 'd1_bom',
            time: 10,
            enable: false
        },
        {
            item: 'D2_TOP',
            field: 'd2_top',
            time: 10,
            enable: false
        }, {
            item: 'D2_BOM',
            field: 'd2_bom',
            time: 10,
            enable: false
        }, {
            item: 'D3_TOP',
            field: 'd3_top',
            time: 10,
            enable: false
        }, {
            item: 'D3_BOM',
            field: 'd3_bom',
            time: 10,
            enable: false
        }
    ]
}

const SELECT_TOWER_CONTROL_TYPE = {
    'thcs' : [
        {
            item: 'RX1',
            field: 'rx1',
            time: 20,
            enable: false
        }, {
            item: 'RX2',
            field: 'rx2',
            time: 20,
            enable: false
        }, {
            item: 'RX3',
            field: 'rx3',
            time: 10,
            enable: false
        }
    ]
}

export {
    REMOTE_CONTROL_TABLE_OPTIOINS,
    REMOTE_CONTROL_TABLE_COLUMS,
    SELECT_TOWER_CONTROL_TYPE,
    REMOTE_CONTROL_TYPE
}